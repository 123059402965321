<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:left-action>
        <arrow-left-icon
          class="arrow-left"
          @click.prevent="closePacketAgeCheck"
        />
      </template>

      <template v-slot:title>
        <h2>{{ $t("packetAgeCheck.age-verification-packet") }}</h2>
        <h1 v-if="packet.barcode">
          {{ packet.barcode.substring(0, packet.barcode.length - 3)
          }}<span class="black">{{
            packet.barcode.substring(packet.barcode.length - 3)
          }}</span>
        </h1>
      </template>
    </AppHeader>
    <section class="age-notice">
      <alert-circle-icon class="alert-circle-icon" />
      <p v-html="$t('packetAgeCheck.age-notice', { date: minimalAgeDate })" />
    </section>
    <main>
      <section class="control-text">
        <p class="text-center">
          <strong>{{ $t("packetAgeCheck.age-control-packet") }}</strong>
        </p>
        <p class="text-center light-grey">
          {{ $t("packetAgeCheck.choice-document-type") }}
        </p>
      </section>
      <section class="container text-center">
        <form>
          <label>
            {{ $t("packetAgeCheck.document-type") }}
            <span class="mandatory">*</span>
            <select v-model="documentType">
              <option :value="DOCUMENT_TYPES.ID">
                {{ $t("packetAgeCheck.id") }}
              </option>
              <option :value="DOCUMENT_TYPES.DL">
                {{ $t("packetAgeCheck.driving-license") }}
              </option>
              <option :value="DOCUMENT_TYPES.PP">
                {{ $t("packetAgeCheck.passport") }}
              </option>
            </select>
          </label>

          <label>
            {{ $t("packetAgeCheck.name") }}<span class="mandatory">*</span>
            <input
              type="text"
              v-model="documentName"
              @input="validateDocumentName"
            />
          </label>

          <label>
            {{ $t("packetAgeCheck.document-number") }}
            <span class="mandatory">*</span>
            <input
              type="text"
              v-model="documentNumber"
              @input="handleLengthUnfocus"
              maxlength="4"
              autofocus
            />
          </label>
        </form>
      </section>

      <section class="error">
        <Errors :errors="errors" />
      </section>

      <footer class="footer-white">
        <div class="container">
          <a
            href="#"
            :class="[
              'btn btn-primary',
              documentType &&
              documentNumber &&
              documentNumber.length == 4 &&
              documentName &&
              documentName.length > 4
                ? 'btn-green'
                : 'btn-grey disable'
            ]"
            @click.prevent="confirmDeliver"
            >{{ $t("packetAgeCheck.confirm-data") }}</a
          >
          <a href="#" class="a-black" @click.prevent="closePacketAgeCheck">{{
            $t("packetReturnShipment.cancel")
          }}</a>
        </div>
      </footer>
    </main>
    <OverlayAccept
      v-if="showAcceptOverlay"
      :heading="$t('packetCheck.release-correct-packet')"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { delay } from "lodash";
import AppHeader from "@/components/AppHeader";
import OverlayAccept from "@/components/OverlayAccept";
import AlertCircleIcon from "vue-material-design-icons/AlertCircle.vue";
import ArrowLeftIcon from "vue-material-design-icons/ArrowLeft.vue";
import Errors from "@/components/Errors";
import { getParams } from "@/mixins/platform-params.js";
import { sendBarcodeResultToMobile } from "@/mixins/send-barcode-result.js";
import { sendHeadersToMobile } from "@/mixins/send-headers.js";

const ADULT_AGE = 18;
const DOCUMENT_TYPES = {
  ID: "personal_id",
  DL: "driver_license",
  PP: "passport"
};

export default {
  name: "PacketAgeCheck",
  mixins: [getParams, sendBarcodeResultToMobile, sendHeadersToMobile],
  components: {
    AppHeader,
    OverlayAccept,
    AlertCircleIcon,
    ArrowLeftIcon,
    Errors
  },
  data() {
    return {
      code: this.$route.params.code,
      documentType: DOCUMENT_TYPES.ID,
      documentName: null,
      documentNumber: null,
      showAcceptOverlay: false,
      DOCUMENT_TYPES
    };
  },

  async mounted() {
    await this.ensureData();
    this.autofillDocumentName();
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    if (to.name == "packet-search") {
      this.resetPacketAndPaymentState();
    }
    next();
  },

  computed: {
    ...mapState(["errors"]),
    ...mapState("packet", ["packet", "searchCode", "readyToPick"]),
    ...mapGetters("auth", ["packetCheckEnabled"]),

    minimalAgeDate() {
      const date = new Date();

      date.setFullYear(date.getFullYear() - ADULT_AGE);

      return date.toLocaleDateString(this.$i18n.locale, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      });
    }
  },

  methods: {
    ...mapActions(["resetPacketAndPaymentState"]),
    ...mapActions("packet", ["searchPassword", "deliverPacket"]),
    ...mapMutations(["clearErrors"]),

    async ensureData() {
      if (this.searchCode) return;

      const response = await this.searchPassword(this.code);
      if (response) return;

      this.backToSearch();
    },

    validateDocumentName() {
      this.documentName = this.documentName.replace(/[0-9]/g, "");
    },

    handleLengthUnfocus({ target }) {
      if (target.value.length < target.maxLength) return;

      target.blur();
    },

    autofillDocumentName() {
      if (!this.packet.recipient) return;

      const nameParts = Object.values(this.packet.recipient);
      const fullName = nameParts.filter(Boolean).join(" ");

      this.documentName = fullName;
    },

    async confirmDeliver() {
      const response = await this.deliverPacket({
        barcode: this.packet.barcode,
        password: this.searchCode,
        document_type: this.documentType,
        document_number: this.documentNumber,
        full_name: this.documentName
      });
      if (!response) return;

      if (this.isMobile) {
        this.packetCheckEnabled && this.sendBarcodeResultToMobile(true);

        this.sendHeadersToMobile(
          this.packet.barcode,
          this.searchCode,
          this.readyToPick
        );
      }

      this.showAcceptOverlayScreenAndRoute();
    },

    showAcceptOverlayScreenAndRoute() {
      this.showAcceptOverlay = true;
      delay(() => {
        this.showAcceptOverlay = false;
        this.$router.replace({
          name: "packet-detail",
          params: { code: this.searchCode },
          query: {
            platform: this.platform,
            device: this.device
          }
        });
      }, 1000);
    },

    closePacketAgeCheck() {
      if (window.confirm(this.$t("packetAgeCheck.window-close"))) {
        this.backToSearch();
      }
    },

    backToSearch() {
      this.$router.replace({
        name: "packet-search",
        query: {
          platform: this.platform,
          device: this.device
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.age-notice {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: $white;
  gap: 0.5rem;
  background-color: $orange-red;
}

main {
  padding: 30px 20px 0;

  .control-text {
    margin-bottom: 25px;
  }

  label {
    text-align: left;
    display: block;
    font-weight: 700;
  }

  select,
  input {
    margin-top: 2px;
    margin-bottom: 13px;

    &[type="number"] {
      padding: 12px 9px;
      font-size: 1.375rem !important;
      line-height: 1;
    }
  }

  footer {
    a {
      margin-bottom: 10px;

      &.a-black:last-child {
        display: inline-block;
        margin: 16px 0 26px;
      }
    }
  }
}
</style>
