<template>
  <div class="red text-center" v-if="errors && errors.length > 0">
    <close v-if="errorBig" class="close" :size="190" />
    <close v-if="errorMedium" class="close" :size="160" />
    <ul class="error" v-if="errors[0] && errors[0].code">
      <li v-for="error in errors" :key="error.code">
        <p>{{ error.text }}</p>
      </li>
    </ul>
    <p class="error" v-else-if="errors[0] && errors[0].text">
      {{ errors[0].text }}
    </p>
  </div>
</template>

<script>
import Close from "vue-material-design-icons/Close.vue";

export default {
  name: "Errors",
  components: { Close },
  props: {
    errors: {
      type: [Array, Object],
      required: true
    },
    errorMedium: {
      type: Boolean,
      default: false
    },
    errorBig: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.close {
  svg {
    margin: -20px 0 -40px;
  }
}
</style>
